<template>
    <v-container>
      <div class="px-md-3 px-1 py-12">
        <h3>Photo d'identitée</h3>
  
        <div class="px-5 mt-5">
          <v-btn
            color="#144881"
            dark
            class="mb-2 mr-md-8 d-md-inline d-block"
            @click="addItem"
            outlined
            pill
            rounded
          >
            <v-icon left> mdi-account-outline </v-icon>
            Téléverser les <strong>photos d'identités des étudiants</strong>
          </v-btn>
        </div>
      </div>
  
      <v-dialog v-model="editorDialog" max-width="1000px" class="black">
        <editor-x
          v-if="editorDialog"
          :fields="fields"
          :model="Model"
          :callback="callBack"
          @success="successEditor"
          :withoutImages="false"
          :title="
            Model && Model._id
              ? `Modification d'un frais standard`
              : `Téléversement les photos d'identités des étudiants`
          "
          :actionBtnLabel="Model && Model._id ? `Modifier` : `Téléverser`"
        />
      </v-dialog>
    </v-container>
  </template>
  
  <script>
  import { mapActions, mapGetters } from "vuex";
  import { defaultMethods, messages } from "@/utils/data";
  import {
    uplaodStudentAvatars,
    updateStandardFees,
    deleteStandardFees,
    addAdditionalCoast,
    updateAdditionalCoast,
    deleteAdditionalCoast,
  } from "@/api/user";
  import EditorX from "@/components/universal/EditorX.vue";
  export default {
    name: "Setting",
    components: {
      EditorX,
    },
    data: () => ({
      callBack: "",
      dialog: false,
      loading: false,
      editorDialog: false,
      editedIndex: -1,
  
      Model: {},
    }),
    mounted() {
      // this.init();
    },
    computed: {
      ...mapGetters(["StandardFees", "AdditionalCoasts"]),
  
      fields() {
        return [
          {
            name: "images",
            label: "Images",
            type: "File",
            isFiles:true,
            col: 12,
            md: 12,
            multipleCol: 2,
            multipleMdCol: 3,
            isRequiredForUpdate: false,
            isRequiredForAdd: false,
            existForUpdate: true,
            existForAdd: true,
            icon: "mdi-upload",
          },
        ];
      },
    },
    methods: {
      ...mapActions(["getAdditionalAndStandardFees"]),
      closeForm() {
        this.Model = {};
        this.editorDialog = false;
        this.editedIndex = -1;
      },
  
      async init() {
        this.loading = true;
        try {
          console.log("init");
        } catch (error) {
          defaultMethods.dispatchError(
            this.$store,
            messages.failedToLoad("les données")
          );
        }
        this.loading = false;
      },
      successEditor() {
        this.editorDialog = false;
        this.deleteDialog = false;
        this.additionalEditorDialog = false;
        this.aditionalDeleteDialog = false;
  
        // this.init();
      },
      addItem() {
        this.editedIndex = -1;
        this.Model = {};
        this.callBack = uplaodStudentAvatars;
        this.editorDialog = true;
      },
      updateItem(item) {
        this.editedIndex = 1;
        this.Model = Object.assign({}, item);
        this.editorDialog = true;
        this.callBack = updateStandardFees;
      },
  
      deleteItem(item) {
        this.deleteDialog = true;
        this.Model = Object.assign({}, item);
        this.callBack = deleteStandardFees;
      },
      addAdditionalCoast() {
        this.editedIndex = -1;
        this.Model = {};
        this.callBack = addAdditionalCoast;
        this.additionalEditorDialog = true;
      },
      updateAdditionalCoast(item) {
        this.editedIndex = 1;
        this.Model = Object.assign({}, item);
        this.additionalEditorDialog = true;
        this.callBack = updateAdditionalCoast;
      },
  
      deleteAdditionalCoast(item) {
        this.aditionalDeleteDialog = true;
        this.Model = Object.assign({}, item);
        this.callBack = deleteAdditionalCoast;
      },
    },
  };
  </script>